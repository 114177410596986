html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #efefef;
  overflow-x:hidden;
  font-family: 'Abel', sans-serif;
}
.rs-calendar-table-cell-day{
  color:#000
}

.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content, .rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover  {
  background: none;
  cursor: not-allowed;
  color: #c5c6c7 !important;
  text-decoration: line-through;
  text-decoration-color:red !important;
  text-decoration-thickness: 3px !important;
}
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content, .rs-calendar-table-cell-disabled  .rs-calendar-table-cell-day{
  color: #c5c6c7 !important;
}

.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content, .rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover, .rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content, .rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-day{
  color: #c5c6c7 !important;
}

#root {
  height: 100%;
}

.header {
  width: 100%;
  background-color: #5386E4;
  height: 180px;
  z-index: 10;
  padding: 20px;
  box-shadow: inset 0px -5px 13px -5px #f8f9fc;
  margin-bottom: 10px;
}

.title {
  font-family: 'Abel', sans-serif;
  color: white;
  padding-left: 16px;
  margin-top: 3px;
}

.alert {
  padding: 0.3rem 0.5rem;
  animation-duration: 3s;
  animation-name: appear;
  animation-iteration-count: infinite;
}

@keyframes appear {
  0% {
    opacity: 0.1;
  }

  40% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }

  100% {
    opacity: 0.1;
  }
}

.alert h5 {
  margin-top: 4px;
}
a:-webkit-any-link {
  text-decoration: none;
}

/*
.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-brand img {
  width: 45px;
}

.user-dropdown {
  width: 25px;
}*/

.form-signin {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  padding: 0 50px 50px;
  max-width: 450px;
  position: relative;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.493);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.493);
  text-align: center;
  padding-top: 0;
  background: white;
}

.form-signin .checkbox {
  font-weight: 400;
}
.form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
  color: #000;
}
.form-control:focus {
  z-index: 2;
  background-color: #0047293c;
  color: black;
  border: 2px solid rgb(97, 97, 97);
  box-shadow: none;
  border-radius: 5px 5px 5px 5px;
  caret-color: #004729;
}

.form-control::placeholder {
  z-index: 2; 
  color: #000;
}

.form-signin input[type='email'] {
  margin-bottom: -1px;
}
.form-signin input[type='password'] {
  margin-bottom: 10px;
}

.logo-login {
  margin: 0 20px;
  padding: 10px;
}
.captcha {
  background-color: rgba(255, 255, 255, 0.411) !important;
}

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.text-modal-content {
  font-size: 2rem;
  color: black;
  font-family: 'Abel', sans-serif;
}

.text-modal-alert {
  font-size: 1.5rem;
  color: #792113;
  font-family: 'Abel', sans-serif;
  border: none;
  box-shadow: 0px 0px 8px 0px rgba(246, 243, 174, 0.5);
}

.text-modal {
  font-size: 1.5rem;
  color: black;
  font-family: 'Abel', sans-serif;
  border: none;
  line-height: 15px;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

#wrap,
.wrapper {
  min-height: 100%;
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 20px;
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: '';
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #0d0d0d;
}

.underlineHover:hover:after {
  width: 100%;
}

.logo {
  max-width: 90%;
  width: 300px;
}

.footer {
  background-color: rgba(2, 8, 43, 0.438);
  width: 100%;
  height: 50px;
  bottom: 0;
  margin-top: 80px;
  z-index: 900;
}
.general {
  padding-bottom: 80px;
}

/*
.footer:before {
  content:'';
  display: block;
  width:100%;
  background-color: #5386E4;
  
}
*/
.green {
  color: #00b800;
}

.red {
  color: #d20606;
}

.footer > div {
  padding: 0.6rem 0;
}

.text-vtasks {
  color: #4e73df;
}

.btn-vtasks {
  color: white;
}

.icon-vtasks {
  color: lightgrey;
}

h1 > span,
h2 > span {
  color: #3fc2cc;
  font-weight: 500;
}

.text-position-title {
  color: #4fc2d0;
}
.wrapper {
  padding-left: 0;
  padding-right: 0;
  align-items: center !important;
}
@media (max-width: 576px) {
  .form-signin {
    padding: 0 20px 50px;
  }

  .wrapper {
    padding-left: 0;
    padding-right: 0;
    align-items: center !important;
  }
}

h3 {
  font-family: 'Abel', sans-serif;
  color: white;
  margin-left: 10px;
  margin-bottom: 10px;
  font-size: 2rem;
  padding-left: 20px;
  margin-top: 10px;
}
h3 i {
  color: white;
}

.circuloActive {
  width: 10px;
  height: 10px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #5cb85c;
  margin-left: 16px;
  margin-top: 8px;
  border: 1px solid white;
  animation-name: appear;
  animation-iteration-count: infinite;
  animation-duration: 2s;
  box-shadow: 0px 0px 19px 0px rgba(255, 255, 255, 0.5);
}
.circuloInactive {
  width: 10px;
  height: 10px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #ff1919;
  margin-left: 16px;
  margin-top: 8px;
  border: 0.5px solid white;
  animation-name: appear;
  animation-iteration-count: infinite;
  animation-duration: 2s;
  box-shadow: 0px 0px 19px 0px rgba(255, 255, 255, 0.5);
}

span {
  font-family: 'Abel', sans-serif;
  color: rgb(197, 197, 197);
  font-size: 1.3rem;
}

.butt {
  background-color: #E31D1C !important;
  box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.5) !important;
  border: none !important;
}

.link{
  color: #E31D1C !important;
  font-family: 'Abel', sans-serif;
}

.butt:hover {
  background-color: #ef3027;
  box-shadow: 0px 0px 8px 0px white;
  border: none;
}

.buttEdit {
  background-color: #5386E4;
  box-shadow: 0px 0px 8px 0px white;
  border: none;
  width: 30px;
  height: 30px;
}

.buttEdit:hover {
  background-color: #ef3027;
  box-shadow: 0px 0px 8px 0px white;
  border: none;
}
.buttEdit span {
  font-size: 0.8rem;
}



.backgrundGraph {
  background-color: rgba(190, 190, 190, 0.61);
  padding: 20px;
  border-radius: 20px;
}

.table-hover tr:hover {
  background-color: rgba(0, 0, 0, 0.301) !important;
}

label {
  font-size: 0.8rem !important;
}

.css-1wa3eu0-placeholder {
  line-height: 0.9rem;
}

.text-grey {
  color: #949396;
}