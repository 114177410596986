.pull-right {
  float: right !important;
}

.some-top-air {
  margin-top: 12px;
}

[hidden] {
  display: none !important;
}

.some-air {
  margin-top: 12px;
  margin-left: 20px;
}
