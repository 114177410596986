
.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value{
    color:black !important;
    font-size: 16px;
    font-family:  Kanit,sans-serif;
  }
  
.rs-picker-subtle .rs-btn:focus, .rs-picker-subtle .rs-btn:hover, .rs-picker-subtle .rs-picker-toggle:focus, .rs-picker-subtle .rs-picker-toggle:hover {

  background-color:inherit;
}

.rs-picker-subtle .rs-btn-active, .rs-picker-subtle .rs-picker-toggle-active{
  background-color:inherit;
}

.rs-picker-subtle .rs-btn.rs-btn-active, .rs-picker-subtle .rs-btn:active, .rs-picker-subtle .rs-picker-toggle.rs-btn-active, .rs-picker-subtle .rs-picker-toggle:active{
  background-color:inherit;
}

.rs-anim-fade.rs-anim-in{
  z-index: 50000;
}